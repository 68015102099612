<template>
  <v-container style="max-width: 1300px">
    <v-row no-gutters>
      <v-col cols="12">
        <h1 class="text-h4">{{ $lang.labels.profile }}</h1>
      </v-col>
      <v-col cols="12">
        <v-divider class="my-2"></v-divider>
      </v-col>
    </v-row>
    <v-row v-if="err || success" dense no-gutters class="px-0">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <v-card class="pa-4 pb-8">
          <v-row dense no-gutters class="fill-height">
            <v-tabs
              v-model="tab"
            >
              <v-tabs-slider color="accent"></v-tabs-slider>

              <v-tab
                :key="0"
                data-cy="tab-visuals"
              >
                {{ $lang.labels.visuals }}
              </v-tab>

              <v-tab
                :key="1"
                data-cy="tab-display"
              >
                {{ $lang.labels.display }}
              </v-tab>
              <v-tab
                :key="2"
                data-cy="tab-admin"
              >
                {{ $lang.labels.passwordChange }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="fill-height" style="width: 100%">
              <v-tab-item
                :key="0"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12">
                    <h5 class="text-h5 ml-2">{{ $lang.labels.theme }}</h5>
                  </v-col>
                  <template v-for="(theme, i) in themes">
                    <v-btn
                      :key="i"
                      :color="formData.visuals.selectedTheme === theme.value ? 'primary' : 'grey'"
                      :class="{'button-default-width': true, 'ml-2': i !== 0 }"
                      class="mt-2 ml-2"
                      @click="formData.visuals.selectedTheme = theme.value; changeTheme(theme.value === 'dark')"
                    >
                      <v-icon class="mr-1">{{ theme.icon }}</v-icon>
                      {{ theme.text }}
                    </v-btn>
                  </template>
                </v-row>
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12">
                    <h5 class="text-h5 ml-2">{{ $lang.labels.colors }}</h5>
                  </v-col>
                  <template v-for="(color, i) in colorSchemes">
                    <v-btn
                      :key="i + 1000"
                      :color="formData.visuals.selectedColorScheme === color.value ? 'primary' : 'grey'"
                      height="48"
                      class="ml-2 mt-2"
                      @click="formData.visuals.selectedColorScheme = color.value; changeColors(color.value)"
                    >
                      {{ color.text }}:
                      <v-icon class="ml-1" style="border: 1px solid white; border-radius: 50%" :color="color.colorPrimary">mdi-circle</v-icon>
                      <v-icon class="ml-1" style="border: 1px solid white; border-radius: 50%" :color="color.colorAccent">mdi-circle</v-icon>
                      <v-icon class="ml-1" style="border: 1px solid white; border-radius: 50%" :color="color.colorSecondary">mdi-circle</v-icon>
                    </v-btn>
                  </template>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :key="1"
                style="width: 100%"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12" md="3">
                    <v-row dense no-gutters>
                      <v-col cols="12">
                        <h5 class="text-h5 ml-2">{{ $lang.labels.show }}</h5>
                      </v-col>
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showCreatedOn"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.createdOn"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showModifiedOn"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.modifiedOn"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showId"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.id"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showComments"
                          data-cy="show-create-on-switch"
                          :label="$lang.labels.showComments"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showCopyPaste"
                          data-cy="show-copy-paste-switch"
                          :label="$lang.labels.copyPaste"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-row dense no-gutters>
                      <v-col cols="12">
                        <h5 class="text-h5 ml-2">{{ $lang.labels.processDebugger }}</h5>
                      </v-col>
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showFinishedOn"
                          data-cy="show-show-finished-on-switch"
                          :label="$lang.labels.showFinishedOn"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters class="pt-2">
                      <v-col cols="12" class="pl-2">
                        <v-switch
                          v-model="formData.display.showStepType"
                          data-cy="show-show-finished-on-switch"
                          :label="$lang.labels.showStepType"
                          color="success"
                          hide-details
                          dense
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :key="2"
                style="width: 100%"
              >
                <v-row dense no-gutters class="pt-4">
                  <v-col cols="12" md="4">
                    <v-row dense no-gutters>
                      <v-col cols="12">
                        <h5 class="text-h5 ml-2 mb-2">{{ $lang.labels.changePassword }}</h5>
                      </v-col>
                      <v-col cols="12" class="pl-2">
                        <v-text-field
                          v-model="oldPassword"
                          :type="oldPasswordEye ? 'text' : 'password'"
                          :append-icon="oldPasswordEye ? 'mdi-eye-off' : 'mdi-eye'"
                          :label="$lang.labels.oldPassword"
                          outlined
                          :rules="oldPasswordRules"
                          @click:append="oldPasswordEye = !oldPasswordEye"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-text-field
                          v-model="newPassword"
                          :type="newPasswordEye ? 'text' : 'password'"
                          :append-icon="newPasswordEye ? 'mdi-eye-off' : 'mdi-eye'"
                          :label="$lang.labels.newPassword"
                          outlined
                          :rules="newPasswordRules"
                          @click:append="newPasswordEye = !newPasswordEye"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-text-field
                          v-model="confirmPassword"
                          :type="confirmPasswordEye ? 'text' : 'password'"
                          :append-icon="confirmPasswordEye ? 'mdi-eye-off' : 'mdi-eye'"
                          :label="$lang.labels.confirmPassword"
                          outlined
                          :rules="confirmPasswordRules"
                          @click:append="confirmPasswordEye = !confirmPasswordEye"
                          @keyup.enter="submit()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense no-gutters>
                      <v-col cols="12" class="pl-2">
                        <v-btn
                          block
                          class="accent color-accent-text"
                          x-large
                          :disabled="isDisabled()"
                          :loading="loadingPassword"
                          @click="changePassword()"
                        >{{ $lang.labels.setPassword }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-row>
          <v-row dense no-gutters justify="end">
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
            <v-btn
              v-if="tab !== 2"
              :disabled="loading"
              color="primary"
              class="button-default-width ml-2"
              data-cy="module-loadDirection-button"
              @click="submit()"
            >
              {{ $lang.actions.save }}
            </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import {
  getAllUserSettingsUsingGET as getProfile,
  saveUserSettingsUsingPOST as saveProfile,
  updatePasswordUsingPUT as updatePassword
} from '@/utils/api'
import colorsConfig from '@/configs/colorSchemes'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      tab: 0,
      loadingPassword: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      formData: {
        general: {
          selectedLanguage: 'en'
        },
        visuals: {
          selectedTheme: 'light',
          selectedColorScheme: 'default'
        },
        display: {
          showCreatedOn: true,
          showModifiedOn: true,
          showId: true,
          showComments: false,
          showCopyPaste: false,
          showFinishedOn: false,
          showStepType: false
        }
      },
      themes: [
        {
          value: 'light',
          text: this.$lang.labels.light,
          icon: 'mdi-weather-sunny'
        },
        {
          value: 'dark',
          text: this.$lang.labels.dark,
          icon: 'mdi-moon-waning-crescent'
        }
      ],
      colorSchemes: [
        {
          colorPrimary: colorsConfig.default.dark.primary,
          colorAccent: colorsConfig.default.dark.accent,
          colorSecondary: colorsConfig.default.dark.secondary,
          text: 'Default',
          value: 'default'
        },
        {
          colorPrimary: colorsConfig.goldenrod.dark.primary,
          colorAccent: colorsConfig.goldenrod.dark.accent,
          colorSecondary: colorsConfig.goldenrod.dark.secondary,
          text: 'Goldenrod',
          value: 'goldenrod'
        },
        {
          colorPrimary: colorsConfig.magenta.dark.primary,
          colorAccent: colorsConfig.magenta.dark.accent,
          colorSecondary: colorsConfig.magenta.dark.secondary,
          text: 'Magenta',
          value: 'magenta'
        },
        {
          colorPrimary: colorsConfig.violet.dark.primary,
          colorAccent: colorsConfig.violet.dark.accent,
          colorSecondary: colorsConfig.violet.dark.secondary,
          text: 'Violet',
          value: 'violet'
        },
        {
          colorPrimary: colorsConfig.greenCrayola.dark.primary,
          colorAccent: colorsConfig.greenCrayola.dark.accent,
          colorSecondary: colorsConfig.greenCrayola.dark.secondary,
          text: 'Green Crayola',
          value: 'greenCrayola'
        },
        {
          colorPrimary: colorsConfig.blue.dark.primary,
          colorAccent: colorsConfig.blue.dark.accent,
          colorSecondary: colorsConfig.blue.dark.secondary,
          text: 'Blue',
          value: 'blue'
        },
        {
          colorPrimary: colorsConfig.simple.dark.primary,
          colorAccent: colorsConfig.simple.dark.accent,
          colorSecondary: colorsConfig.simple.dark.secondary,
          text: 'Simple',
          value: 'simple'
        }
      ],
      err: '',
      success: '',
      loading: false,
      oldPasswordRules: [
        (v) => !!v || this.$lang.errors.required,
        (v) => (v && v.length >= 6) || this.$lang.errors.passwordLength
      ],
      newPasswordRules: [
        (v) => !!v || this.$lang.errors.required,
        (v) => (v && v.length >= 6) || this.$lang.errors.passwordLength,
        (v) => (v !== this.oldPassword) || this.$lang.errors.passwordsMatch
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$lang.errors.required,
        (v) => (v && v.length >= 6) || this.$lang.errors.passwordLength,
        (v) => (v === this.newPassword) || this.$lang.errors.passwordsDontMatch
      ],
      oldPasswordEye: false,
      newPasswordEye: false,
      confirmPasswordEye: false
    }
  },
  created() {
    this.fetchUserProfile()
  },
  methods: {
    ...mapActions('app', [
      'globalTheme',
      'userSettingsSave'
    ]),
    isDisabled() {
      return this.loading || this.loadingPassword || this.oldPassword === '' || this.newPassword === '' || this.newPassword.length < 6 || this.oldPassword.length < 6 || this.oldPassword === this.newPassword || this.newPassword !== this.confirmPassword
    },
    fetchUserProfile() {
      getProfile()
        .then((res) => {
          const tempData = res.data.data

          if (!tempData.display) {
            tempData.display = {
              showCreatedOn: true,
              showModifiedOn: true,
              showId: true,
              showComments: false,
              showCopyPaste: false,
              showFinishedOn: false,
              showStepType: false
            }
          }

          this.userSettingsSave(tempData)

          if (!tempData.visuals) {
            tempData.visuals = {
              selectedTheme: 'light',
              selectedColorScheme: 'default'
            }
            if (localStorage.selectedTheme) {
              tempData.visuals.selectedTheme = JSON.parse(localStorage.selectedTheme) ? 'dark' : 'light'
            }
          }
          if (!tempData.general) {
            tempData.general = {
              selectedLanguage: 'en'
            }
            if (localStorage.selectedLanguage) {
              tempData.general.selectedLanguage = localStorage.selectedLanguage
            }
          }
          this.formData = tempData
          this.changeTheme(tempData.visuals.selectedTheme === 'dark')
          this.changeColors(tempData.visuals.selectedColorScheme)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changeLang(lang) {
      this.$lang.setLang(lang)
      localStorage.setItem('selectedLanguage', lang)
      this.$router.push({ name: this.$route.name, params: { lang } })
    },
    changeTheme(theme) {
      this.$vuetify.theme.dark = theme
      localStorage.setItem('selectedTheme', theme)
      this.globalTheme(theme)
    },
    changeColors(type) {
      const { dark } = colorsConfig[type]
      const { light } = colorsConfig[type]

      if (dark && light) {
        localStorage.setItem('selectedColorScheme', type)

        // set themes
        Object.keys(dark).forEach((i) => {
          this.$vuetify.theme.themes.dark[i] = dark[i]
        })
        Object.keys(light).forEach((i) => {
          this.$vuetify.theme.themes.light[i] = light[i]
        })
      }
    },
    changePassword() {
      this.err = ''
      this.success = ''
      this.loadingPassword = true
      updatePassword({ request: { oldPassword: this.oldPassword, newPassword: this.newPassword } })
        .then((res) => {
          if (res?.data?.status === 'SUCCESS') {
            this.success = this.$lang.success.passwordChanged
            this.loadingPassword = false
            this.oldPassword = ''
            this.newPassword = ''
            this.confirmPassword = ''
            setTimeout(() => this.success = '', 6000)

            return
          }
          this.loadingPassword = false
          this.err = res.response?.data?.statusText || res.data?.statusText || this.$lang.errors.somethingWentWrong
          setTimeout(() => this.err = '', 6000)
        })
        .catch((err) => {
          console.log(err)
          this.err = err.response?.data?.statusText || this.$lang.errors.somethingWentWrong
          this.loadingPassword = false
          setTimeout(() => this.err = '', 6000)
        })
    },
    submit() {
      this.err = ''
      this.success = ''
      this.loading = true
      saveProfile({ settings: { settings: this.formData } })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            this.success = this.$lang.success.userSettingsSubmit
            this.loading = false
            setTimeout(() => this.success = '', 6000)
          }
          this.loading = false
          this.err = res.data.statusText
          setTimeout(() => this.err = '', 6000)
        })
        .catch((err) => {
          console.log(err)
          this.err = this.$lang.errors.somethingWentWrong
          this.loading = false
          setTimeout(() => this.err = '', 6000)
        })

    }
  }
}
</script>
<style scoped lang="scss">

</style>
